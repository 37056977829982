export const showUpstreamValues = (upstream) => {
    if (upstream === null) 
        return <></>;
    else return (
    <div>
        <h3>Current values</h3>
        <ul class="list-group">
            {Object.entries(upstream).map((key) => 
                <li class="list-group-item" key={key}><b>{key[0]}</b> : {key[1]}</li>
            )}
        </ul>
    </div>
    );
}; 

export const displayErrors = (errorElements) => {
    return errorElements && errorElements.length > 0 ? errorElements.map(element => {
        return <div style={{color: 'red'}}>{element}</div>
    }): <></>
}