import './App.css';
import Main from './components/Main/Main';
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='*' Component={Main} />
      </Routes>
    </div>
  );
}

export default App;
