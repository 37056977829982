import { useState } from "react";
import { displayErrors, showUpstreamValues } from '../Util/Util';
const Form = () => {

    const [upstreamURL, setUpstreamURL] = useState("");
    const [upstreamID, setUpstreamID] = useState("");
    const [type, setType] = useState("ROUTE");
    const [csid, setCsid] = useState("");
    const [password, setPassword] = useState("");
    const [upstream, setUpstream] = useState(null);
    const [errorMap, setErrorMap] = useState({})
    const [generalError, setGeneralError] = useState([]);
    const [upstreamBasicAuthPassword, setUpstreamBasicAuthPassword] = useState("");

    const resetForm = () => {
        setUpstreamURL("");
        setUpstreamID("");
        setType("ROUTE");
        setCsid("");
        setPassword("");
        setUpstream(null);
        setErrorMap({})
        setGeneralError([])
        setUpstreamBasicAuthPassword("");
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        setErrorMap({});
        setGeneralError("");
        const data = {
            csid: csid,
            updatePassword: password,
            upstreamUrl: upstreamURL,
            upstreamId: upstreamID,
            type: type,
            upstreamBasicAuthPassword: upstreamBasicAuthPassword
        }
        let response;
        
        response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upstream`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
              }, 
            body: JSON.stringify(data)
        })

        const parsedResponse = await response.json();
        if (response.status !== 200) {
            handleError(response.status, parsedResponse);
        } else {            
            setUpstream(parsedResponse);
        }    
    }
  
    const handleError = (statusCode, response) => {

        if (statusCode === 500) {
            setGeneralError([response.message]);
            return;
        }

        if (statusCode === 401) {
            setGeneralError(["Not authorized to post upstream values with given CSID and password combination"])
        }
        
        if (statusCode === 404) {
            setGeneralError(["Not able to find upstream values with given CSID and password combination"])
        }

        if (statusCode === 400) {
            const groupedCodes = {};

            response.fieldExceptions.forEach(x => {
                if (groupedCodes[x.field] !== undefined) {
                    groupedCodes[x.field] =  groupedCodes[x.field].concat([x.message])
                } else {
                    groupedCodes[x.field] = [x.message];
                }
            })

            setErrorMap(groupedCodes);
        }
        
    }
    
    return (
    <div class="container d-flex justify-content-center flex-column">
    <form class="needs-validation" onSubmit={(event) => submitHandler(event)}>
        <div class="form-group has-validation">
            <label for="upstreamUrl">Upstream URL</label>
            <br/>
            <small id="emailHelp" class="form-text text-muted">Required protocols: http <b>|</b> </small>
            <small id="emailHelp" class="form-text text-muted">https <b>|</b> </small>
            <small id="emailHelp" class="form-text text-muted">ws <b>|</b> </small>
            <small id="emailHelp" class="form-text text-muted">wss </small>
            <small id="emailHelp" class="form-text text-muted">Example: http://some_url.com </small>
            <input type="text" className="form-control" id="upstreamUrl" aria-describedby="upstreamUrlHelp" placeholder="Enter Upstream URL" onChange={(e) => setUpstreamURL(e.target.value)} required/>
            {displayErrors(errorMap.upstreamUrl)}
            
        </div>
        <div class="form-group has-validation">
            <label for="upstreamId">Upstream ID</label>
            <br/>
            <small id="emailHelp" class="form-text text-muted">Example: some_id</small>
            <input type="text" className="form-control" id="upstreamId" placeholder="Enter ID here" onChange={(e) => setUpstreamID(e.target.value)} required/>
            {displayErrors(errorMap.upstreamId)}
        </div>
         <div class="form-group">
            <label for="type">Type</label> <br/>
            <small id="emailHelp" class="form-text text-muted mb-3">Note, when devices connects through mobile network, route handling will be of type ROUTE even though REDIRECT is set. </small>
            <select id="routeTypes" name="routeTypes" class="form-select form-select-sm" onChange={(e) => setType(e.target.value)}>
                <option value="ROUTE" selected="selected">Route</option>
                <option value="REDIRECT">Redirect</option>
            </select>
        </div>
        <div class="form-group">
            <label for="upstreamId">DEFA Power ID</label>
            <input type="text" className="form-control" id="upstreamId" placeholder="Enter CSID here" onChange={(e) => setCsid(e.target.value)} required/>
            {displayErrors(errorMap.csid)}
        </div>
        <div class="form-group mb-3">
            <label for="upstreamId">Password</label>
            <input type="password" className="form-control" id="upstreamId" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required/>
            {displayErrors(errorMap.updatePassword)}
        </div>
        <div class="form-group mb-3">
            <label for="upstreamBasicAuthPassword">Upstream Basic Auth Password</label>
            <input type="password" className="form-control" id="upstreamId" placeholder="Enter password used to auth against upstream (optional)" onChange={(e) => setUpstreamBasicAuthPassword(e.target.value)}/>
        </div>
        <button type="submit" className="btn btn-primary" style={{marginRight: '10px'}}>Submit</button>
        <button type="reset" className="btn btn-secondary" onClick={() => resetForm()}>Clear</button>
    </form> 
    {showUpstreamValues(upstream)}
    {displayErrors(generalError)}
    </div>
    )
}

export default Form;