import { useState } from "react";
import { displayErrors, showUpstreamValues } from '../Util/Util';

const Get = () => {
    
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [upstream, setUpstream] = useState(null);
    const [errors, setErrors] = useState([])

    const submitHandler = async (event) => {
        event.preventDefault();
        setUpstream(null);
        setErrors([]);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upstream/${username}?` + new URLSearchParams({
            password: password}), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
              }, 
        });
        
        const parsedResponse = await response.json();
        if (response.status === 200) {
            setUpstream(parsedResponse);
        } else if (response.status === 404) {
            setErrors(["Unable to locate upstream values of given CSID and password combination"])
        } else if (response.status === 401) {
            setErrors(["Unauthorized to get upstream for given CSID and password combination"]);
        } else if (response.status === 500) {
            setErrors(["Internal server error"]);
        }
        

    }
    return (
        <div class="container d-flex justify-content-center flex-column">
            <form onSubmit={(event) => submitHandler(event)}>
            <div class="form-group">
                <label for="upstreamUrl">DEFA Power Id</label>
                <input type="text" className="form-control" id="upstreamUrl" aria-describedby="upstreamUrlHelp" placeholder="Enter CSID here" onChange={(e) => setUsername(e.target.value)} required/>
            </div>
            <div class="form-group">
                <label for="upstreamUrl">Password</label>
                <input type="password" className="form-control mb-3" id="upstreamUrl" aria-describedby="upstreamUrlHelp" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required/>
            </div>
            <button type="submit" className="btn btn-primary mb-3">Get Upstream</button>
            </form>
            {displayErrors(errors)}
            {showUpstreamValues(upstream)}
        </div>
    );
}

export default Get;
