import { useState } from "react"
import Form from "../Form/Form";
import Get from '../Get/Get'
const Main = () => {
    const [toggle, setToggle] = useState(true);
    return(
        <div class="position-relative">
            {toggle ?
                <>
                    <button type="button" class="btn btn-outline-primary end-10" onClick={() => setToggle(false)}>Get</button>
                    <Form />
                </>
                :
                <>
                    <button type="button" class="btn btn-outline-primary end-25" onClick={() => setToggle(true)}>Upload</button>
                    <Get />
                </>
            }
        </div>
    )
}

export default Main
